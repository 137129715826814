import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const PracticeCenter = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="навчально-практичний центр">

			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rqb-9y4X4m4mYlFugjqDBw_10wseAav4/view?usp=sharing" target="_blanck">						
					Наказ ДОН ЛОДА про створення НПЦ будівельного напряму
					</a>
				</h4>
			
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ydrWIiTDxABrso1x-qy0fBJtsB198VTX/view?usp=drive_link" target="_blanck">						
					Наказ ЛВПУ КТБ про створення НПЦ будівельного напряму
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1caTNoC96jzkmPAd6EZ6xfcdxgqpPwRHe/view?usp=drive_link" target="_blanck">						
					Положення про НПЦ будівельного напряму
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1xJWf_SoU2vvlvYzPfeG1ySSqDVre3L51/view?usp=drive_link" target="_blanck">						
					План роботи НПЦ на 2024 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1-uihiAZjlmTXK7WqLHo4c4rusr1xMwQs/view?usp=drive_link" target="_blanck">						
					План роботи НПЦ на 2025 рік
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default PracticeCenter;