import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const AdmissionRules = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="правила прийому">

			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/16DmgLw3E3t3p-qNHJ90Z9-HPJypDpeOs/view?usp=drive_link" target="_blanck">
						Правила прийому до Державного професійного-технічного навчального закладу "Львівське вище професійне училище комп'ютерних технологій та будівництва" у 2025 році.
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/15NPq9gRX5JgLZyO1hZYbNDJmhzKEG8PM/view?usp=drive_link" target="_blanck">
						Правила прийому до Державного професійного-технічного навчального закладу "Львівське вище професійне училище комп'ютерних технологій та будівництва" у 2024 році.
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://www.youtube.com/watch?v=KMWYMigCBQU" target="_blanck">
					ВСТУП-2023: Реєстрація електронного кабінету та подання заяв до закладів професійної освіти (ПТО)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.youtube.com/watch?v=56zaaXPW8ZQ" target="_blanck">
					ВСТУП-2023: Реєстрація електронного кабінету та подання заяв до закладів ФПО на основі ПЗСО, КР
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1kaqy8jT6cgRojYRpg6ShEdQdemKo8lA0/view?ts=63ef413a" target="_blanck">
						Правила прийому до Державного професійного-технічного навчального закладу "Львівське вище професійне училище комп'ютерних технологій та будівництва" у 2023 році.
					</a>
				</h4>
								
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ENt0RGXm4lXp1uFphstrvxrLZ1UbhThz/view?usp=sharing" target="_blanck">
						МОТИВАЦІЙНИЙ ЛИСТ
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1s8TZkBjU8k2w3QDCYkol-rP6yrhp5wc3/view?usp=sharing" target="_blanck">
						Правила прийому для здобуття освітньо-професійного ступеня фахового молодшого бакалавра у 2024 році.
					</a>
				</h4>
				<br />
				<h4>
					<a href="https://drive.google.com/file/d/1t8Ep-7IpHeADYTOh7ytguqZj2kU1aPTK/view?usp=drive_link" target="_blanck">
					Алгоритм дій під час сигналу "Повітряна тривога" під час проведення співбесіди
					</a>
				</h4>
			<br />
				<h4>
					<a href="https://docs.google.com/document/d/1k1ZghA4vBJnNxaqwSoTdcoIq6Ig_wPdi/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">
					Вимоги та рекомендації щодо оформлення мотиваційних листів 
					</a>
				</h4>
				<br />
				<h4>
					<a href="https://docs.google.com/document/d/14ynycx-DoCnxafFkiTphMsqXFFuIBY7I/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">
					Програма співбесіди з української мови 
					</a>
				</h4>
			<br />
				<h4>
					<a href="https://drive.google.com/file/d/1Ay3r44aYqOpBMboqYuKb_jsiOVrHPda6/view?usp=drive_link" target="_blanck">
					Фахова співбесіда 192 Будівництво та цивільна інженерія 
					</a>
				</h4>
				<br />
				<h4>
					<a href="https://docs.google.com/document/d/1IjvSY0M1zDkJ03jjBMq_59nZ_djeM0DM/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">
					Фахова співбесіда 274 Автомобільний транспорт 
					</a>
				</h4> 
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1r8da-nprVZMzeTDE24BSfH6Sk_pdUBfb/view?usp=sharing" target="_blanck">
						Правила прийому для підготовки кваліфікованих робітників на 2022 рік
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1wpfTZzGim9R5DDkZnoCVhGwqWcQx1fkt/view?usp=sharing" target="_blanck">
						Правила прийому для здобуття освітньо-професійного ступеня фахового молодшого бакалавра на 2022 рік
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default AdmissionRules;