import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const KiberSecurity = () => {	
	return (
		<article>
			<Header />
			<Pattern title="Запобігання шахрайству та цифрова гігієна">
			<br />
				<h4>				
					<a href="https://mvs.gov.ua/public/nacionalna-strategiia-spriiannia-rozvitku-gromadianskogo-suspilstva-v-ukrayini-na-2021-2026-roki/rekomendaciyi-shhodo-zapobigannia-viniknenniu-peredumov-ucinennia-pravoporusen-u-sferi-blagodiinoyi-ta-volonterskoyi-diialnosti-1" target="_blanck">
					Роз'яснення щодо правових аспектів благодійної та волонтерської діяльності
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1_tVO6qtRmJTR2lfOicUjBwkqOaDToufs/view" target="_blanck">
					Електронна брошура «Азартні ігри – це незвичайна гра: чому варто бути
					обережними» розроблена Комісією з регулювання азартних ігор та лотерей (КРАІЛ)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rKLcaSyerFcqto0yfuE2vW1gkXtNpMFX/view" target="_blanck">
					Соціальний ролик «Стоп «ДРОП» для протидії використання
					зловмисниками реквізитів банківських карток/рахунків громадян
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://www.gc.gov.ua/ua/Informatsiini-povidomlennia/35440.html" target="_blanck">
					Детальна інформація про використання зловмисниками реквізитів
					банківських карток/рахунків громадян
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://imzo.gov.ua/psyholohichnyj-suprovid-ta-sotsialno-pedahohichna-robota/materialy-z-preventsii-zalezhnosti-vid-azartnykh-ihor/" target="_blanck">
					Матеріали з превенції залежності від азартних ігор
					</a>
				</h4>			
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default KiberSecurity;