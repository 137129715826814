import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const OurPartners = () => {
	return (
		<article>
			<Header />

			<Pattern title="Наші Партнери">
			
			<br />
				<h4>
				<a href="https://ivet.edu.ua/partnery/" target="_blanck">						
				ІНСТИТУТ ПРОФЕСІЙНОЇ ОСВІТИ НАПН УКРАЇНИ
					</a>
					</h4>
								
					<br />
				<h4>				
					
				Заходи від Інституту професійної освіти НАПН України, які відвідали працівники ЛВПУ КТБ:
					</h4>
				<br />
				<h4>				
					<a href="https://youtu.be/BdkzeyrwrBg?si=dtOWgrVA7hb6CiRs" target="_blanck">						
					Професійна освіта і навчання в умовах сучасних викликів
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://youtu.be/YnqWNt6S4CU?si=MCfUo2J1PCmyHhMM" target="_blanck">						
					Методологічні й теоретичні засади модернізації професійної освіти в сучасних умовах
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://surl.li/nlefjo" target="_blanck">						
					ПРОГРАМА Всеукраїнського науково-практичного вебінару (у форматі он-лайн) «МЕТОДИКА ОРГАНІЗАЦІЇ ПРОФЕСІЙНОЇ ПІДГОТОВКИ МАЙБУТНІХ КВАЛІФІКОВАНИХ РОБІТНИКІВ ЗА ДУАЛЬНОЮ ФОРМОЮ ЗДОБУТТЯ ОСВІТИ»
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://youtu.be/vGUOwz0TMrM?si=uZc_BBnjIyTNuUtJ" target="_blanck">						
					Обговорення проблем запровадження дуальної форми здобуття освіти
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://youtu.be/9me2Z12Vjmw?si=d4dJbp77oe14cKqL" target="_blanck">						
					Проект Єразмус+ 2018
					</a>
				</h4>
			</Pattern>

			<Partners />

			<Footer />
		</article>
	)
};

export default OurPartners;
